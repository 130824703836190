import React from 'react';
import CustomHtmlPrompt from '../../../components/inputs/CustomHtmlPrompt';

class TPIThankyou extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: {}, nextButtonClass: 'display-none' };
  }

  render() {
    const {
      surveyPresent,
      thankyouText,
      thankyouHtmlSafe,
      thankYouText1,
      thankYouText2,
      displayWarning,
      warningTextPresent,
      cautionImg,
      optinThankyou,
      cautionText,
    } = this.props;
    return (
      <div id="tpi_thank_you">
        {surveyPresent ? (
          <div id="thank_you">
            <div className="page-inner-content" role="main">
              {thankyouText && (
                <div>
                  <p className="tpi_text_head">
                    <CustomHtmlPrompt label={thankyouHtmlSafe} />
                  </p>
                  <p className="tpi_text">
                    <CustomHtmlPrompt label={thankYouText1} />
                  </p>
                </div>
              )}
              <div className="click_here">
                <CustomHtmlPrompt label={thankYouText2} />
              </div>
            </div>
            {displayWarning && warningTextPresent && (
              <div className="thank_you_caution" tabIndex="0">
                <img
                  alt=""
                  tabIndex="0"
                  className="caution_img"
                  src={`/assets/${cautionImg}`}
                />
                <p className="caution_text">
                  <CustomHtmlPrompt label={cautionText} />
                  <br />
                </p>
              </div>
            )}
          </div>
        ) : (
          <div
            tabIndex="0"
            dangerouslySetInnerHTML={{ __html: optinThankyou }}
          />
        )}
      </div>
    );
  }
}

export default TPIThankyou;
